import * as React from "react";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import terms from "./userDataRegistro.css";
// services


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




//class="MuiFormControl-root MuiTextField-root css-1u3bzj6-MuiFormControl-root-MuiTextField-root"
export default function SimpleAccordion() {

  const arr_test = [{"value":"TITULAR","translation":"Datos Personales"},{"value":"Padre","translation":"Datos Paternos"},{"value":"Madre","translation":"Datos Maternos"},{"value":"HIJO","translation":"Datos Hijos/as"},{"value":"Hija","translation":"Datos Hijas/Hijos"},{"value":"HERMANO","translation":"Datos Hermanos"},{"value":"Hermana","translation":"Datos Hermanas"},{"value":"Conyugue","translation":"Datos Pareja"}]
  // "Datos Personales","Datos Paternos","Datos Maternos","Hijo","Hija","Hermano","Hermana","Conyuge"]
  const [datosRegistro, setDatosRegistro] = React.useState([]);
  useEffect(() => {
    fetch("http://localhost:5000/datos_registro")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDatosRegistro(data);
      })
      .catch((error) => console.error(error));
  }, []);


function findTranslation(relacion) {
  let translation = "";
  arr_test.forEach((item) => {
    if (item.value === relacion) {
      translation = item.translation;
    }
  });
  return translation;
}

  
  return (
    
    <div>
       <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={'span'}>Datos Registro Civil</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Typography component={'span'}>
                  <div className="civil-container">
                    
                
{datosRegistro.respuesta?.respuesta.map((item, index) => {
        return( 
         1
        // <div>
        
        //  <ul> {findTranslation(item.relacion)}
        //    <li key={Math.floor(Math.random() * 999)}>
        //      <TextField
        //        id="standard-read-only-input"
        //        label="Apellido"
        //        defaultValue={item.apellido}
        //        InputProps={{
        //          readOnly: true,
        //        }}
        //        variant="standard"
        //        />
        //      </li>
        //      <li key={Math.floor(Math.random() * 999)}>
        //      <TextField
        //        id="standard-read-only-input"
        //        label="Nombre"
        //        defaultValue= {item.nombre}
        //        InputProps={{
        //          readOnly: true,
        //        }}
        //        variant="standard"
        //        />
        //      </li>
        //      <li key={Math.floor(Math.random() * 999)}>
        //      <TextField
        //        id="standard-read-only-input"
        //        label="Tipo de Documento"
        //        defaultValue=  {item.tipodoc}
        //        InputProps={{
        //          readOnly: true,
        //        }}
        //        variant="standard"
        //        />
        //      </li>
        //      <li key={Math.floor(Math.random() * 999)}>
        //      <TextField
        //        id="standard-read-only-input"
        //        label="Documento"
        //        defaultValue=  {item.dni}
        //        InputProps={{
        //          readOnly: true,
        //        }}
        //        variant="standard"
        //         />
        //       </li>
        //       <li key={Math.floor(Math.random() * 999)}>  
        //       <TextField 
        //        id="standard-read-only-input"
        //        label="Fecha de Nacimiento"

        //        defaultValue=  {item.fecha_nac}
        //        InputProps={{
        //          readOnly: true,
        //        }}
        //        variant="standard"
        //        />
        //      </li>
        //      <li key={Math.floor(Math.random() * 999)}>
        //      <TextField
        //        id="standard-read-only-input"
        //        label="Nacionalidad"
        //        defaultValue=  {item.nacionalidad}
        //        InputProps={{
        //          readOnly: true,
        //        }}
        //        variant="standard"
        //        />
        //      </li>
        //      <li key={Math.floor(Math.random() * 999)}>
        //      <TextField
        //        id="standard-read-only-input"
        //        label="Sexo Registrado"
        //        defaultValue=  {item.sexo.charAt(0).toUpperCase() + item.sexo.slice(1)}
        //        InputProps={{
        //          readOnly: true,
        //        }}
        //        variant="standard"
        //        />
        //      </li>
        //    </ul> 
        //  </div>
         )
       
})}
  
                    </div>

                 
                  </Typography>
                </AccordionDetails>
              </Accordion>
              
              <Accordion disabled>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography component={'span'}>Árbol Genealógico</Typography>
                </AccordionSummary>
              </Accordion>
                
                
              </div>  
                
    );
  }
