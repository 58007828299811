import { AnyMxRecord } from "dns";

const axios = require("axios");

export async function getUser(
  inputNumeroDocumento: any,
  inputSexo: any,
  inputNumeroExpediente: any,
  selectedJuzgado: any
) {
  try {
    // console.log("selected juzgado");
    // console.log(selectedJuzgado);
    // console.log( selectedJuzgado["DescriIDNomencladorpcion"])
    // console.log(selectedJuzgado["Descripcion"])
    // console.log(selectedJuzgado["nomenclador"])
    // console.log("selected expediente"); 
    // console.log(inputNumeroExpediente);
    inputSexo === "mujer" ? (inputSexo = "F") : (inputSexo = "M");
    let URL =
      process.env.REACT_APP_URL_BACK +
      "/?numero_documento=" +
      inputNumeroDocumento + 
      "&s=" +
      inputSexo +
      "&juzgado=" +
      selectedJuzgado["Descripcion"] +
      "&cod_nomenclador=" +
      selectedJuzgado["IDNomenclador"] +
      "&cod_meta4=" +
      selectedJuzgado["nomenclador"] +
      "&numero_expediente=" +
      inputNumeroExpediente;
    return await axios.get(URL);
  } catch (error) {
    console.error(error);
  }
}

export async function getUserQuota(userName: any) {
  try {
    let URL = process.env.REACT_APP_URL_BACK + "/userquota";
    return await axios.get(URL);
  } catch (error) {
    console.error(error);
  }
}

export async function getJuzgados() {
  try {
    let URL = process.env.REACT_APP_URL_BACK + "/juzgados";
    return await axios.get(URL);
  } catch (error) {
    console.error(error);
  }
}


export async function getTermsAccepted(username:any) {
  try {
    let URL = process.env.REACT_APP_URL_BACK + "/terms?username=" + username;
    return await axios.get(URL);
  } catch (error) {
    console.error(error);
  }
}


export async function setTermsAccepted(data:any) {
  try {
    let dni=data.dni;
    let caracter=data.caracter;
    let URL = process.env.REACT_APP_URL_BACK + "/terms";
    return await axios.post(URL, {dni,caracter,accept_action:true});
  } catch (error) {
    console.error(error);
  }
}

