
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Result from './components/Result';
import Keycloak from "keycloak-js";
import jwt_decode from "jwt-decode";
import SpaceMan from "./components/js/SpaceMan";
const axios = require('axios');




let initOptions = {
  url: "https://auth.pjm.gob.ar/auth",
  realm: "devops",
  clientId: "renaper",
  onLoad: "login-required",
  checkLoginIframe: false
};
let keycloak = Keycloak(initOptions);
let jwtStatus = false;
keycloak
  .init({ onLoad: initOptions.onLoad })
  .success((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.info("Authenticated");
    }

    localStorage.setItem("token", keycloak.token);
    localStorage.setItem("refresh-token", keycloak.refreshToken);
   
    var token = keycloak.token;
    var decoded = jwt_decode(token);
    console.log('token: \n' + token);
   
    localStorage.setItem("HumanName",decoded.name);
    localStorage.setItem("userName", decoded.email.split("@")[0]);
    if(decoded.resource_access.renaper?.roles?.includes("renaper-consulta"))
    {
      jwtStatus=true;
    }else 
    {
      jwtStatus=false;
    }
    axios.defaults.baseURL = 'https://dev-backend.pjm.gob.ar/ged-backend-cloud-master/';
    axios.defaults.headers.common['Authorization'] ='Bearer '+ keycloak.token;

    axios.interceptors.response.use(function (response) {
      // Do something with response data
      
      if(response.data.code==="401")
      {
        //should be checking this on frontend not back
        // alert("ERROR")
        // alert(response.data.status.error)
      }
      return response;
    }, function (error) {
      // Do something with response error
      return Promise.reject(error);
    });

ReactDOM.render(
  <React.StrictMode>

{jwtStatus?<Result status={jwtStatus} />:<SpaceMan/>}


   
  </React.StrictMode>,
  document.getElementById('root')
);

   
    setTimeout(() => {
      keycloak
        .updateToken(70)
        .success((refreshed) => {
          if (refreshed) {
            console.debug("Token refreshed" + refreshed);
            axios.defaults.headers.common['Authorization'] = 'Bearer '+refreshed;
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .error(() => {
          console.error("Failed to refresh token");
        });
    }, 60000);
  })
  .error(() => {
    console.error("Authenticated Failed");
  });


















// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
