import * as React from "react";
import Grid from "@mui/material/Grid";
import {useEffect} from "react";

import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// services 
import {getUser} from '../services/persona';
import './userData.css'
import './Consult.js'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocuPDF from "./docuPDF";
import { hasFormSubmit } from "@testing-library/user-event/dist/utils";


 


export default function UserData(toChild) {
  
//recibe  componente Consult.js y devuelve los datos de la persona

const[displayPDFDocu, setDisplayPDFDocu]=React.useState(false)
const[jsonDatos, setJsonDatos]=React.useState({})
useEffect(() => {
     console.log("from user data comp")
     console.log(toChild)
     
} , [toChild])



useEffect(() => {
// console.log("from user data comp")

setJsonDatos({toChild})


} , [])

function generarConstanciaHandler() {
  setDisplayPDFDocu(true)
 }
 
 

   
let wholeReturn=(
      
      
  <div className="containerregistro">
    <div className="img">
    <img 
        src={`data:image/jpeg;base64,${toChild?.data?.userData?.foto}`} 
        style={{  width:"95%",height:"100%", objectFit:"cover" }} 
    />
</div>
    <div className="apellido">
    <TextField
      id="Apellido2"
      label="Apellido"
     
      value={toChild?.data?.userData?.apellido??""}
      InputProps={{
        readOnly: true,
      }}
      variant="standard"
    />
    </div>
    <div className="nombre">
    <TextField
    id="Nombre"
    label="Nombre"
    
    value={toChild?.data?.userData?.nombres??""}
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="edad">
      <TextField
    id="Edad"
    label="Edad" value={toChild.edad??""}
  
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />

    </div>
    <div className="nacimiento">
    <TextField
    id="Naciemiento"
    label="Fecha de Nacimiento"
    
    value={toChild?.data?.userData?.fecha_nacimiento??""}
     
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="ejemplar">
    <TextField
    id="Ejemplar"
    label="Ejemplar"
   
    value={toChild?.data?.userData?.ejemplar??""}
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    {/* <div className="emision">
    <TextField
    id="Fecha"
    label="Fecha de emision"

    value={toChild?.data?.userData?.EMISION??""}
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="vencimiento">
    <TextField
    id="vencimiento"
    label="Fecha de vencimiento"
    value={toChild?.data?.userData?.VENCIMIENTO??""}
  
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
      
    </div> */}
    <div className="pais">
    <TextField
    id="pais"
    label="País"

    value={toChild?.data?.userData?.pais??""}
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="provincia">
    <TextField
    id="prov"
    label="Provincia"
   
    value={toChild?.data?.userData?.provincia??""}
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="ciudad">
    <TextField
    id="ciudad"
    label="Ciudad"

    value={toChild.data?.userData?.ciudad?.toUpperCase().replace(/_/g, ' ')??""}
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="domicilio">
    <TextField
   fullWidth
    id="sdomic"
    label="Domicilio"
    value={toChild.domicilio??""}
    
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="cp">
    <TextField
    id="cp"
    label="Codigo Postal"
    value={toChild?.data?.userData?.codigo_postal??""}
 
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="sexo" >
    <TextField
    id="sex"
    label="Sexo registrado al nacer"
    value={toChild.sexo.toUpperCase()??""} 

    variant="standard"
    />
    

    </div>
    <div className="nacionalidad">
    <TextField
    id="muni"
    label="Municipio"
  
    value={toChild?.data?.userData?.municipio?.toUpperCase().replace(/_/g, ' ')??""}

    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    </div>
    <div className="dni">
    <TextField
    id="dni"
    label="Documento"
   
    value={toChild.documento??""}
    InputProps={{
      readOnly: true,
    }}
    variant="standard"
    />
    
    </div>
    
    <div className="xx">
    {toChild?.data?.userData?.mensaje_fallecido!=="Sin Aviso de Fallecimiento" && <span className="xx" style={{color:'red'}}>
    <strong>Persona Fallecida</strong></span> }
    </div>

    <div className="pdf">
      <DocuPDF jsonDatos={jsonDatos } />
      

    </div>
    
  </div>

);

  return (
      wholeReturn
      
   );




}




