import * as React from "react";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// services
import { getTermsAccepted } from "../services/persona";
import { setTermsAccepted } from "../services/persona";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { Grid3x3 } from "@mui/icons-material";
import terms from "./terms.css";
export default function Terms() {
  useEffect(() => {
    // console.log("on terms dialog");
    setHumanName(localStorage.getItem("HumanName"));
    getTermsAccepted(localStorage.getItem("userName")).then((data) => {
      data = data.data.data; // what in the hell is this? TODO map this and make it more readable
      // console.log("terms dialog useeffect");
      // console.log(data);
      // console.log(!data.accepted);
      if (!data.accepted) {
        setOpen(true);
      } else {
        setOpen(false);
      }
      //setTerms(data.data)
    });
  }, []);

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [DNI, setDNI] = React.useState("");
  const [humanName, setHumanName] = React.useState("");
  const [caracter, setCaracter] = React.useState("");
  const [acceptButtonDisabled, setAcceptButtonDisabled] = React.useState(true);
  const [showInputError, setShowInputError] = React.useState(false);

  useEffect(() => {
    // console.log("on terms dialog");
    let dni_int = 0;
    try {
      dni_int = parseInt(DNI);
    } catch (error) {
      // console.log("cant parse dni to verify valid mask");
      dni_int = 0;
    }
    if (caracter.length > 3 && dni_int > 7000000 && dni_int < 400000000) {
      setAcceptButtonDisabled(false);
      setShowInputError(false);
    } else {
      setAcceptButtonDisabled(true);
      setShowInputError(true);
    }
  }, [caracter, DNI]);
  const handleDNIInput = (event) => {
    setDNI(event.target.value);
  };
  const handleCaracterInput = (event) => {
    setCaracter(event.target.value);
  };
  const handleAcceptButton = () => {
    // console.log("accept button clicked");
    // console.log(DNI);
    // console.log(caracter);

    let obj = {};
    obj.dni = DNI;
    obj.caracter = caracter;
    obj.humanName = humanName;
    setTermsAccepted(obj).then((data) => {
      let d = data.data;
      // console.log("D RESPONSE IS");
      // console.log(d);
      if (d.status.state === "ok") {
        setOpen(false);
      }
    });
  };

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        // onClose={handleClose}
      >
        <DialogTitle>Términos y Condiciones de uso</DialogTitle>
        <DialogContent>
          <DialogContentText style={{textAlign:"left"}}>
          <div> <strong>Acta de confidencialidad:</strong> Al acceder a la utilización de este</div>
           <div>servicio, de acuerdo a los requerimientos realizados por el Registro</div>
           <div> Nacional de las Personas, en los términos de su Resolución n°</div>
           <div> 2979/13, la persona de nombre <strong>{humanName}</strong></div>
           <div>  DNI N°{" "} <input size="25" onChange={handleDNIInput} ></input> en su carácter de{" "}</div>
           <div> <input  size="33" onChange={handleCaracterInput}></input> de la Suprema Corte</div>
           <div> de Justicia de la Provincia de Mendoza , acepta bajo juramento</div>
           <div>  guardar absoluta reserva y asume el compromiso de utilizar la</div>
           <div> información de dicho organismo exclusiva y únicamente en los</div>
           <div>  términos de la Ley n° 25326"</div> 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div class="container">
            <div class="btn" style={{marginRight: "20px" }}>
              <Button
                style={{
                  backgroundColor:
                    acceptButtonDisabled === true ? "gray" : "lightGreen",
                  margin: "10px",
                }}
                disabled={acceptButtonDisabled}
                onClick={handleAcceptButton}
              >
                Acepto
              </Button>
              <Button style={{ backgroundColor: "tomato", margin: "10px" }}>
                No Acepto
              </Button>
            </div>
            <div class="text"  style={{marginRight: "20px" }}>
              {showInputError && (
                <div class="eee">
                  Ambos campos son obligatorios. DNI solo números
                </div>
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
