import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';


// import "../../utils/spaceman.js";
 import "./../../utils/spaceman.css";

import logopjm from '../../utils/escudo-pjm01_animated.svg';
export default function SpaceMan() {
 

  function logout()
{
  let initOptions = {
    url: "https://dev-auth.pjm.gob.ar/auth",
    realm: "devops",
    clientId: "renaper",
    onLoad: "login-required",
  } 

  let  encoded_url=process.env.REACT_APP_URL_LOGOUT_REDIRECT_ENCODED

  let logout_url=initOptions.url + '/realms/' + initOptions.realm + '/protocol/openid-connect/logout?redirect_uri=' + encoded_url
  // console.log(logout_url);
   localStorage.clear();
   
  // alert()
   window.location.replace(logout_url);
 
}


  return (
    <div>
    <div className="centerAll" >

      
   
      <img className='PjmLogoJumper' src = "https://pjm-resources.obs.sa-argentina-1.myhuaweicloud.com/icons/escudopjm-mediano358x423.png" alt = "pjm" height = "150px" width = "120px" />


  </div>


<div className="centerAll" >

      
   

<h3>Su usuario no tiene permisos para usar esta aplicación, por favor solicítelo generando un ticket &nbsp; 
<a href={process.env.REACT_APP_URL_FRESHDESK_TICKET}>Solicitar Aquí</a>
</h3>

</div>


<div className="centerButton" >

<Button variant="outlined"   onClick={()=>{  logout() }}>  Volver al Login</Button>
   
{/* <Button
              size="large"
              edge="end"
              aria-label="Cerrar Sesion"
              
              aria-haspopup="true"
             
              color="blue"
              onClick={()=>{  logout() }}
            >
             Volver al Login
            </Button> */}




</div>



</div>


  );
}

